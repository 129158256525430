import "./Footer.css";

function Footer() {
    return (
        <footer className="footer-flex">
            <p>CVR: 45234576</p>
            <div className="vl"></div>
            <p>kontakt@saelv.dk</p>
        </footer>
    )
}

export default Footer;